import React, { FC } from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import styled from 'styled-components'

const StyImageContainer = styled.div`
  padding: 2rem;
`

const StyZoomBox = styled.div`
  overflow: hidden;
  float: left;

  display: flex;
  align-items: center;

  box-shadow: 0 0.2rem 0.3rem 0 rgba(0, 0, 0, 0.8);
`

const StyImgBox = styled.div`
  width: min(30rem, 100vw - 4rem);

  transition: transform 2s;
  :hover {
    transform: scale(1.2);
  }
`

export type PostSummaryProps = {
  slug: string
  gatsbyImageData: IGatsbyImageData
}

const PostSummaryItem: FC<PostSummaryProps> = ({
  slug, gatsbyImageData,
}) => (
  <StyImageContainer>
    <a href={slug}>
      <StyZoomBox>
        <StyImgBox>
          <GatsbyImage image={gatsbyImageData} alt="" />
        </StyImgBox>
      </StyZoomBox>
    </a>
  </StyImageContainer>
)

export default PostSummaryItem
