// eslint-disable-next-line no-use-before-define
import React, { FC } from 'react'
import PostSummary from '../components/post-summary'
import MainPage from '../components/main-page'

const Home: FC<{}> = () => (
  <MainPage>
    <PostSummary />
  </MainPage>
)

export default Home
