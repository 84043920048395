import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import PostSummaryItem, { PostSummaryProps } from './post-summary-item'
import SocialMediaRow from './social-media-row'

const StyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
`

const StyPage = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 30rem;
  max-width: 30rem;
  justify-content: center;
  padding: 2rem;
`

const StyPageTitle = styled.div`
  font-size: 4rem;
`

const StyPageDescription = styled.div`
  margin-top: 2rem;
  font-size: 1rem;
`

type PostsQueryResult = {
  allDirectory: {
    edges: {
      node: {
        name: string
        relativePath: string
        fields: {
          slug: string
          date: string
          name: string
        }
      }
    }[]
  }
  coverImages: {
    nodes: {
      relativeDirectory: string
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData
      }
    }[]
  }
  site: { siteMetadata: { title: string, description: string } }
}

const PostSummary = () => {
  const queryResult: PostsQueryResult = useStaticQuery(graphql`query PostsQuery {
  allDirectory(filter: {fields: {slug: {ne: null}}}) {
    edges {
      node {
        name
        relativePath
        fields {
          slug
          date
          name
        }
      }
    }
  }
  coverImages: allFile(
    filter: {extension: {regex: "/jpg|png/"}, name: {regex: "/cover/"}}
  ) {
    nodes {
      relativeDirectory
      childImageSharp {
        gatsbyImageData(width: 600, placeholder: NONE, layout: CONSTRAINED)
      }
    }
  }
  site {
    siteMetadata {
      title
      description
    }
  }
}
`)

  const { title, description } = queryResult.site.siteMetadata

  const postSummaryPropsMap = new Map<string, PostSummaryProps>()
  const dirs = queryResult.allDirectory.edges.map((edge) => edge.node)
  const sortedDirs = dirs.sort((dir1, dir2) => (dir2.fields.date > dir1.fields.date ? 1 : -1))
  const coverImageFiles = queryResult.coverImages.nodes

  sortedDirs.forEach(dir => {
    const gatsbyImageData = coverImageFiles.reduce((acc, file) => {
      if (file.relativeDirectory === dir.relativePath) return file.childImageSharp.gatsbyImageData
      return acc
    }, {} as IGatsbyImageData)

    postSummaryPropsMap.set(dir.name, {
      slug: dir.fields.slug,
      gatsbyImageData,
    })
  })

  return (
    <StyContainer>
      <StyPage>
        <StyPageTitle>
          { title }
        </StyPageTitle>
        <SocialMediaRow />
        <StyPageDescription>
          { description }
          <p />
          Most of the bricks were a present from our neighbors who gave us them unsorted in a huge box.
        </StyPageDescription>
      </StyPage>
      {
        Array.from(postSummaryPropsMap.values()).map(postSummaryProps => (
          <PostSummaryItem {...postSummaryProps} key={postSummaryProps.slug} />
        ))
      }
    </StyContainer>
  )
}

export default PostSummary
